<template>
  <div class="login">
    <img class="logo" :src="`${publicPath}img/logo.png`" alt="" />
    <div class="form-container">
      <div class="form">
        
        <div class="tab">
          <div
            :class="{
              'tab-item': true,
              active: tabSelectedIndex === ROOM_ID_LOGIN_TYPE,
            }"
            @click="tabSelectedIndex = ROOM_ID_LOGIN_TYPE"
          >
          Meeting Login 
          </div>
          
          <!-- <div
            :class="{
              'tab-item': true,
              active: tabSelectedIndex === ACCOUNT_LOGIN_TYPE,
            }"
            @click="tabSelectedIndex = ACCOUNT_LOGIN_TYPE"
          >
            账号登录
          </div> -->
          
        </div>
        <a-form-model
          :model="formData"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <template v-if="tabSelectedIndex === ACCOUNT_LOGIN_TYPE">
            <a-form-model-item>
              <a-input
                v-model="formData.userAccount"
                placeholder="用户名/手机号/邮箱"
                :max-length="32"
              >
                <div slot="prefix" class="input-pre-icon">
                  <user-icon />
                </div>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="formData.userPass"
                type="password"
                placeholder="密码"
              >
                <div slot="prefix" class="input-pre-icon">
                  <lock-icon />
                </div>
              </a-input>
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item>
              <a-input v-model="formData.roomId" placeholder="Room ID">
                <div slot="prefix" class="input-pre-icon">
                  <room-icon />
                </div>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="formData.password"
                type="password"
                placeholder="Room password"
              >
                <div slot="prefix" class="input-pre-icon">
                  <lock-icon />
                </div>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="formData.userName"
                placeholder="Your name"
                :max-length="32"
              >
                <div slot="prefix" class="input-pre-icon">
                  <name-icon />
                </div>
              </a-input>
            </a-form-model-item>
          </template>
          <div class="error-message">{{ errorMessage }}</div>
          <a-button block class="submit-btn" type="primary" html-type="submit">
            Join Now
          </a-button>

          <div class="lang">
            <a href="http://cloudmeetingen.glafamily.cn/">English</a>
            <a href="http://cloudmeeting.glafamily.cn/">中文</a>
          </div>

        </a-form-model>
      </div>
    </div>
    <a-modal
      class="setting-modal"
      title="设置"
      v-model="settingModalShow"
      :footer="null"
    >
      <a-checkbox v-model="settingAddr" @change="setAddrChange"
        >设置网络登录服务器地址</a-checkbox
      >
      <div class="addr">
        <span>服务器地址</span>
        <a-input
          class="addr-input"
          v-model="serverIP"
          :max-length="100"
          :disabled="!settingAddr"
        />
        <span>端口</span>
        <a-input
          class="port-input"
          v-model="serverPort"
          :max-length="10"
          :disabled="!settingAddr"
        />
      </div>
      <div class="btn-wrapper">
        <a-button
          class="apply-btn"
          type="primary"
          :disabled="!settingAddr || !serverIP"
          @click="applyAddr"
        >
          应用
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import appConfig from '@/config/appConfig'
import { userIcon, lockIcon, roomIcon, nameIcon } from '@/core/icons'
import { setStorage } from '@/utils/util'

const wse = window.sessionStorage
const ACCOUNT_LOGIN_TYPE = 1
const ROOM_ID_LOGIN_TYPE = 2
const { defaultServer } = appConfig

const setCache = (key, value) => {
  localStorage.getItem(key) === null && localStorage.setItem(key, value)
}

const joinAddr = (ip, port) => {
  const { protocol } = defaultServer
  return `${protocol}://${ip}${port ? ':' + port : ''}`
}

export default {
  name: 'Login',
  components: { userIcon, lockIcon, roomIcon, nameIcon },
  data() {
    const { apply, ip, port = '' } = defaultServer
    setCache('settingAddr', apply)
    setCache('serverIP', ip)
    setCache('serverPort', port)
    return {
      ACCOUNT_LOGIN_TYPE,
      ROOM_ID_LOGIN_TYPE,
      tabSelectedIndex:
        wse.getItem('loginTab') === null
          ? ROOM_ID_LOGIN_TYPE
          : Number(wse.getItem('loginTab')),
      formData: {
        userAccount: '',
        userPass: '',

        roomId: '',
        password: '',
        userName: '',
      },
      settingAddr: localStorage.getItem('settingAddr') === 'true',
      serverIP: localStorage.getItem('serverIP') || '',
      serverPort: localStorage.getItem('serverPort') || '',
      settingModalShow: false,
      errorMessage: '',
      publicPath: process.env.BASE_URL,
    }
  },
  created() {
    const applyedCesAddr = localStorage.getItem('applyedCesAddr')
    const { settingAddr, serverIP, serverPort } = this
    if (settingAddr && applyedCesAddr === null) {
      localStorage.setItem('applyedCesAddr', joinAddr(serverIP, serverPort))
    }
    // 自动填充上次输入
    this.formData.userAccount = localStorage.getItem('login_userAccount') || ''
    this.formData.userPass = localStorage.getItem('login_userPass') || ''

    this.formData.roomId = localStorage.getItem('login_roomId')
    this.formData.password = localStorage.getItem('login_roomPwd') || ''
    this.formData.userName = localStorage.getItem('login_userName') || ''
  },
  watch: {
    tabSelectedIndex(value) {
      this.errorMessage = ''
      wse.setItem('loginTab', value)
    },
    settingModalShow(value) {
      if (!value) {
        this.serverIP = localStorage.getItem('serverIP')
        this.serverPort = localStorage.getItem('serverPort')
      }
    },
  },
  methods: {
    setAddrChange(e) {
      localStorage.setItem('settingAddr', e.target.checked)
    },
    applyAddr() {
      // 服务器地址不能是汉字，端口只能是数字
      const ipReg = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
      const portReg = /^[0-9]+$/
      const ipVaild = ipReg.test(this.serverIP)
      const portVaild = this.serverPort ? portReg.test(this.serverPort) : true
      if (ipVaild && portVaild) {
        const { serverIP, serverPort } = this
        this.settingModalShow = false
        localStorage.setItem('serverIP', serverIP)
        localStorage.setItem('serverPort', serverPort)
        localStorage.setItem('applyedCesAddr', joinAddr(serverIP, serverPort))
      } else {
        const messageMap = {
          truefalse: '端口错误',
          falsetrue: '服务器地址错误',
          falsefalse: '服务器地址及端口错误',
        }
        this.$message.warning(messageMap[`${ipVaild}${portVaild}`])
      }
    },
    handleSubmit() {
      const { tabSelectedIndex, formData } = this
      let requiredField = []
      if (tabSelectedIndex === ACCOUNT_LOGIN_TYPE) {
        requiredField = [
          {
            key: 'userAccount',
            title: '用户名',
          },
          {
            key: 'userPass',
            title: '密码',
          },
        ]
      } else {
        requiredField = [
          {
            key: 'roomId',
            title: 'Room ID',
          },
          {
            key: 'userName',
            title: 'Name(姓名)',
          },
        ]
      }
      let invalidField = []
      requiredField.forEach(e => {
        !formData[e.key] && invalidField.push(e.title)
      })
      if (invalidField.length > 0) {
        this.errorMessage = 'Please enter ' + invalidField.join(' and ')
      } else {
        this.errorMessage = ''
        const {
          settingAddr,
          tabSelectedIndex,
          formData: { userAccount, userPass, roomId, password, userName },
        } = this
        const applyedCesAddr = localStorage.getItem('applyedCesAddr')
        const baseQuery = {
          cesAddr: settingAddr && applyedCesAddr ? applyedCesAddr : undefined,
          loginTab: tabSelectedIndex,
        }
        if (tabSelectedIndex === ACCOUNT_LOGIN_TYPE) {
          const query = {
            ...baseQuery,
            userAccount,
            // userPass,
          }
          setStorage('userPass', userPass)
          this.$router.push({ path: '/meeting-list', query })
        } else {
          const query = {
            ...baseQuery,
            roomId,
            roomPwd: password || undefined,
            userName,
          }
          wse.setItem('meeting_page_from', 'login')
          this.$router.push({ path: '/meeting', query })
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
@form-container-width: 1332px;
@form-container-height: 534px;
.login {
  @padding: 30px;
  width: 100%;
  height: 100%;
  // min-width: @form-container-width + @padding * 2;
  // min-height: @form-container-height + @padding * 2;
  padding: @padding;
  background: url(~@/assets/img/login/bg-page.png) no-repeat center/cover;

  .lang{
    display: flex;
    justify-content: center;
    a {
      padding:20px 30px;
      padding-bottom: 0;
    }
  }

  .form-container {
    display: flex;
    max-width: @form-container-width;
    height: @form-container-height;
    justify-content: center;
    align-items: center;
    margin: 100px auto 30px;
    background: url(~@/assets/img/login/bg-container.png) no-repeat center/cover;

    .form {
      width: 400px;
      padding: 50px;
      border-radius: 8px;
      background: #fff;
      position: relative;

      .setting-btn {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 18px;
      }

      .tab {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 50px;

        &-item {
          font-size: 24px;
          color: #42424a;
          cursor: pointer;

          &.active {
            color: #3c98fe;
            position: relative;
            padding-bottom: 10px;

            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 4px;
              background: #3c98fe;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }

      /deep/ .ant-form-item {
        margin-bottom: 20px;

        .ant-form-item-control {
          line-height: 44px;

          .ant-input-affix-wrapper {
            .ant-input-prefix {
              left: 0;

              .input-pre-icon {
                width: 35px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #ebedf0;

                path {
                  fill: #949bae;
                }
              }
            }

            .ant-input {
              height: 44px;
              padding-left: 45px;

              &:hover {
                border-color: #3c98fe;
              }

              &:focus {
                border-color: #3c98fe;
                box-shadow: 0 0 0 2px rgba(60, 152, 254, 0.2);
              }
            }

            &:hover .ant-input {
              border-color: #3c98fe;
            }
          }
        }
      }

      .error-message {
        color: #f5222d;
        min-height: 30px;
        padding: 5px 0;
        line-height: 20px;
      }

      .submit-btn {
        height: 40px;
        background: #3c98fe;
        border-color: #3c98fe;
      }
    }
  }
}

.setting-modal {
  /deep/ .ant-modal-body {
    padding: 30px;

    .addr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      margin-top: 10px;

      .addr-input {
        margin-left: 20px;
        margin-right: 60px;
      }

      .port-input {
        width: 80px;
        margin-left: 20px;
      }
    }

    .btn-wrapper {
      text-align: right;
      margin-top: 30px;

      .apply-btn {
        width: 100px;
      }
    }
  }
}
</style>
